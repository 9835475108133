import 'react-app-polyfill/stable';
import './styles/index.scss';

import { createBrowserHistory } from 'history';
// eslint-disable-next-line no-restricted-imports
import isNil from 'lodash/isNil';
import { render } from 'react-dom';
import { Route, Router, Switch } from 'react-router-dom';

import { setWindowNavigator } from './helpers/navigation';
import { deserialize } from './helpers/stateSerializerDeserializer';
import { DEFAULT_LANGUAGE } from './i18n';
import * as middlewares from './redux/middlewares';
import renderApp from './renderApp';
import { reportWebVitals, sendToGA } from './reportWebVitals';

(function f() {
  if (typeof window.CustomEvent === 'function') {
    return false;
  }

  function CustomEvent(event: any, params: any) {
    const parameters = params || {
      bubbles: false,
      cancelable: false,
      detail: undefined,
    };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(
      event,
      parameters.bubbles,
      parameters.cancelable,
      parameters.detail,
    );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'Event' is missing the following properties f... Remove this comment to see the full error message
  window.CustomEvent = CustomEvent;
  return true;
})();

setWindowNavigator();

const preloadedState = isNil(window.PRELOADED_STATE)
  ? undefined
  : deserialize(window.PRELOADED_STATE);
const preloadedLanguage = window.PRELOADED_LANGUAGE ?? DEFAULT_LANGUAGE;

const { rootElement } = renderApp({
  history: createBrowserHistory({
    basename:
      preloadedLanguage === DEFAULT_LANGUAGE
        ? undefined
        : `/${preloadedLanguage}`,
  }),
  authorizationHeader: undefined,
  initialState: preloadedState,
  language: preloadedLanguage,
  Components: { Router, Switch, Route },
  middlewares,
});

const rootNode = document.getElementById('root');

render(rootElement, rootNode);

reportWebVitals(sendToGA);
