import type { Record as ImmutableRecord } from 'immutable';
// This is the only place where it is okay!
import {
  // eslint-disable-next-line no-restricted-imports
  useDispatch as originalUseDispatch,
  // eslint-disable-next-line no-restricted-imports
  useSelector as originalUseSelector,
} from 'react-redux';

import type { Action } from '../redux/actions';
import type { BackendSessionState } from '../redux/reducers/backendSession';
import type { BackendSessionJsonRpcState } from '../redux/reducers/backendSessionJsonRpc';
import type { BootReducerState } from '../redux/reducers/boot';
import type { CodeExplanationState } from '../redux/reducers/codeExplanation';
import type { ContentAuthorizationState } from '../redux/reducers/contentAuthorization';
import type { DatawarehouseSessionState } from '../redux/reducers/datawarehouseSession';
import type { ImagesState } from '../redux/reducers/images';
import type { LearningModeState } from '../redux/reducers/learningMode';
import type { LearningRecapState } from '../redux/reducers/learningRecap';
import type { NotesState } from '../redux/reducers/notes';
import type { OnboardingMilestonesState } from '../redux/reducers/onboardingMilestones';
import type { OutputState } from '../redux/reducers/output';
import type { PreFetchedDataState } from '../redux/reducers/preFetchedData';
import type { StreakInfoState } from '../redux/reducers/streaks/types';

export type StateShape = {
  backendSession: BackendSessionState;
  backendSessionJsonRpc: BackendSessionJsonRpcState;
  boot: BootReducerState;
  chapter: unknown;
  codeExplanation: CodeExplanationState;
  contentAuthorization: ContentAuthorizationState;
  course: unknown;
  datawarehouseSession: DatawarehouseSessionState;
  exercises: unknown;
  images: ImagesState;
  learningMode: LearningModeState;
  learningRecap: LearningRecapState;
  location: unknown;
  mobilePopup: unknown;
  notes: NotesState;
  onboardingMilestones: OnboardingMilestonesState;
  output: OutputState;
  preFetchedData: PreFetchedDataState;
  settings: unknown;
  streakInfo: StreakInfoState;
  systemStatus: unknown;
  user: unknown;
};
export type State = ImmutableRecord.Instance<StateShape>;

export type GetState = () => State;
export type Thunk = (
  dispatch: Dispatch,
  getState: GetState,
) => Action | Promise<void> | { type: string } | void;
export type Dispatch = (action: Action | Thunk) => Promise<void>;
export type Selector<Selected> = (state: State) => Selected;
// eslint-disable-next-line no-shadow
export type Reducer<State> = (
  state: State | undefined,
  action: Action,
) => State;

export const useDispatch: () => Dispatch = originalUseDispatch;
export const useSelector: <TSelected = unknown>(
  selector: Selector<TSelected>,
  equalityFn?: (left: TSelected, right: TSelected) => boolean,
) => TSelected = originalUseSelector;
